import { useEffect, useRef } from "react";
import { graphql, useFragment } from "react-relay";
import { HeaderFragment$key } from "./__generated__/HeaderFragment.graphql";
import { FormattedMessage } from "react-intl";
import NavItem from "./NavItem";
import LogoutButton from "./LogoutButton";
import EntityProfilePic from "./EntityProfilePic";
import Button from "./Button";
import Logo from "./Logo";
import LogoIcon from "./LogoIcon";
import * as Drawer from "./Drawer";
import { MdMenu } from "react-icons/md";
import { useLocation } from "react-router-dom";

const HeaderFragment = graphql`
  fragment HeaderFragment on Query {
    viewer @ifAllowed {
      id
      displayName
      username
      canCreateEvent: can(action: CREATE_EVENT)
      ...EntityProfilePicFragment @arguments(thumbnail: true)
    }
    events(first: 1) {
      edges {
        node {
          id
        }
      }
    }
  }
`;

interface Props {
  query: HeaderFragment$key;
}

export default function Header({ query }: Props) {
  const data = useFragment(HeaderFragment, query);
  const location = useLocation();
  const closeRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    if (closeRef.current) {
      closeRef.current.click();
    }
  }, [location]);

  const showEvents =
    data.events.edges.length > 0 || data.viewer?.canCreateEvent;
  const userButtons = data.viewer ? (
    <>
      <Button kind="text" to={`/${data.viewer.username}`}>
        <div className="flex flex-row items-center">
          <div className="whitespace-nowrap pr-2 hidden lg:block">
            {data.viewer.displayName}
          </div>
          <EntityProfilePic entity={data.viewer} size="8" />
        </div>
      </Button>
      <LogoutButton />
    </>
  ) : (
    <>
      <Button kind="text" to="/login">
        <FormattedMessage defaultMessage="Login" />
      </Button>
      <Button to="/signup">
        <FormattedMessage defaultMessage="Signup" />
      </Button>
    </>
  );
  return (
    <div className="flex flex-row pt-3">
      <Button kind="text" to="/" className="hidden md:block mr-4">
        <div className="w-32 hidden lg:block">
          <Logo />
        </div>
        <div className="w-6 lg:hidden">
          <LogoIcon />
        </div>
      </Button>
      <div className="hidden md:flex flex-row space-x-2 items-center w-full">
        <NavItem to={`/competitions`}>
          <FormattedMessage defaultMessage="Competitions" />
        </NavItem>
        {showEvents && (
          <NavItem to={`/events`}>
            <FormattedMessage defaultMessage="Events" />
          </NavItem>
        )}
        <NavItem to={`/discussions`}>
          <FormattedMessage defaultMessage="Discussions" />
        </NavItem>
      </div>
      <div className="md:hidden w-full">
        <Drawer.Root>
          <Drawer.Trigger asChild>
            <div className="inline-block p-3">
              <MdMenu size={24} />
            </div>
          </Drawer.Trigger>
          <Drawer.Content>
            <div className="flex flex-col pt-4 pb-4">
              <NavItem to={`/`} vertical>
                <FormattedMessage defaultMessage="Home" />
              </NavItem>
              <NavItem to={`/competitions`} vertical>
                <FormattedMessage defaultMessage="Competitions" />
              </NavItem>
              {showEvents && (
                <NavItem to={`/events`} vertical>
                  <FormattedMessage defaultMessage="Events" />
                </NavItem>
              )}
              <NavItem to={`/discussions`} vertical>
                <FormattedMessage defaultMessage="Discussions" />
              </NavItem>
            </div>
          </Drawer.Content>
          <Drawer.Close asChild>
            <button ref={closeRef} className="hidden" />
          </Drawer.Close>
        </Drawer.Root>
      </div>
      <div className="flex flex-grow-0 items-center">{userButtons}</div>
    </div>
  );
}
