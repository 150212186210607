import React from "react";
import { NavLink } from "react-router-dom";
import { cn } from "../utils/tailwind";

interface Props {
  children?: React.ReactNode;
  to: string;
  vertical?: boolean;
  exact?: boolean;
}

export default function NavItem({
  children,
  to,
  vertical = false,
  exact = false,
}: Props) {
  return (
    <NavLink
      to={to}
      end={exact}
      className={({ isActive }) =>
        cn([
          "hover:cursor-pointer px-4 py-2 whitespace-nowrap",
          vertical ? "border-l-4" : "border-y-4",
          isActive
            ? "border-indigo border-t-transparent text-indigo"
            : "border-transparent",
        ])
      }
    >
      {children}
    </NavLink>
  );
}
