import { graphql, useMutation, ConnectionHandler } from "react-relay";
import { useIntl, FormattedMessage } from "react-intl";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useCompetitionTopicCreatePageQuery } from "./loaders/CompetitionTopicCreatePage";
import {
  CompetitionTopicCreatePageMutation$variables,
  CompetitionTopicCreatePageMutation as CompetitionTopicCreatePageMutationType,
} from "./__generated__/CompetitionTopicCreatePageMutation.graphql";
import ErrorPage from "./ErrorPage";
import TopicEditForm from "../components/TopicEditForm";
import { logger } from "../common/logger";
import { DEFAULT_VOTABLE_ORDER } from "../utils/votableOrder";

const CompetitionTopicCreatePageMutation = graphql`
  mutation CompetitionTopicCreatePageMutation(
    $competitionId: ID!
    $input: CreateTopicInput!
    $connections: [ID!]!
  ) {
    createTopicForCompetition(competitionId: $competitionId, input: $input)
      @prependEdge(connections: $connections) {
      node {
        id
      }
    }
  }
`;

export default function CompetitionTopicCreatePage() {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const { slug } = useParams();
  const { query } = useCompetitionTopicCreatePageQuery();
  const competition = query?.competitionBySlug;
  const [commitMutation, isMutationInFlight] =
    useMutation<CompetitionTopicCreatePageMutationType>(
      CompetitionTopicCreatePageMutation,
    );

  const { order } = location.state || {};

  if (!competition) {
    return (
      <ErrorPage
        status={404}
        message={intl.formatMessage({
          defaultMessage: "Competition not found",
        })}
      />
    );
  }

  return (
    <div>
      <h1 className="text-xl font-bold pb-4 font-poppins">
        <FormattedMessage defaultMessage={"New Topic"} />
      </h1>
      <TopicEditForm
        onSubmit={({ data, setFormError }) => {
          const variables: CompetitionTopicCreatePageMutation$variables = {
            competitionId: competition.id,
            input: {
              title: data.title,
              url: data.url || undefined,
              description: data.description || undefined,
            },
            connections: [
              ConnectionHandler.getConnectionID(
                competition.id,
                "CompetitionTopicsFragment_topics",
                { order: order || DEFAULT_VOTABLE_ORDER },
              ),
            ],
          };
          commitMutation({
            variables,
            onError: (error) => {
              logger.error(error);
              setFormError(
                intl.formatMessage({
                  defaultMessage: "Could not create topic :(",
                }),
              );
            },
            onCompleted: (results) => {
              navigate(
                `/competitions/${slug}/discussions/${results.createTopicForCompetition.node.id}`,
              );
            },
          });
        }}
        isDisabled={isMutationInFlight}
      />
    </div>
  );
}
