import { IntlShape } from "react-intl";

export const formatByteSize = (bytes: number, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";
  if (bytes === 1) return "1 Byte";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const formatOrganizationMembershipKind = (
  intl: IntlShape,
  kind: "ADMIN" | "EDITOR" | "OWNER" | "READER",
) => {
  return {
    OWNER: intl.formatMessage({ defaultMessage: "Owner" }),
    ADMIN: intl.formatMessage({ defaultMessage: "Member" }),
    EDITOR: intl.formatMessage({ defaultMessage: "Member" }),
    READER: intl.formatMessage({ defaultMessage: "Member" }),
  }[kind];
};

export const formatCompetitionMembershipKind = (
  intl: IntlShape,
  kind: "HOST" | "PARTICIPANT",
) => {
  return {
    HOST: intl.formatMessage({ defaultMessage: "Host" }),
    PARTICIPANT: intl.formatMessage({ defaultMessage: "Participant" }),
  }[kind];
};
