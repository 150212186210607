/**
 * @generated SignedSource<<5856515d10034f7e396a2a64be6f3d16>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateTopicInput = {
  description?: string | null | undefined;
  title: string;
  url?: any | null | undefined;
};
export type ForumTopicCreatePageMutation$variables = {
  connections: ReadonlyArray<string>;
  forumId: string;
  input: CreateTopicInput;
};
export type ForumTopicCreatePageMutation$data = {
  readonly createTopicForForum: {
    readonly node: {
      readonly id: string;
    };
  };
};
export type ForumTopicCreatePageMutation = {
  response: ForumTopicCreatePageMutation$data;
  variables: ForumTopicCreatePageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "forumId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v3 = [
  {
    "kind": "Variable",
    "name": "forumId",
    "variableName": "forumId"
  },
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v4 = {
  "alias": null,
  "args": (v3/*: any*/),
  "concreteType": "TopicEdge",
  "kind": "LinkedField",
  "name": "createTopicForForum",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Topic",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ForumTopicCreatePageMutation",
    "selections": [
      (v4/*: any*/)
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ForumTopicCreatePageMutation",
    "selections": [
      (v4/*: any*/),
      {
        "alias": null,
        "args": (v3/*: any*/),
        "filters": null,
        "handle": "prependEdge",
        "key": "",
        "kind": "LinkedHandle",
        "name": "createTopicForForum",
        "handleArgs": [
          {
            "kind": "Variable",
            "name": "connections",
            "variableName": "connections"
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "0a0bc6a8981e61c48d4cc91d06031af3",
    "id": null,
    "metadata": {},
    "name": "ForumTopicCreatePageMutation",
    "operationKind": "mutation",
    "text": "mutation ForumTopicCreatePageMutation(\n  $forumId: ID!\n  $input: CreateTopicInput!\n) {\n  createTopicForForum(forumId: $forumId, input: $input) {\n    node {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3e247cab992bcda13f1980f8fb1c5209";

export default node;
