export function getTopicPath(
  topic: string,
  competition: string | null | undefined,
  forum: string | null | undefined,
) {
  return competition
    ? `/competitions/${competition}/discussions/${topic}`
    : forum
      ? `/discussions/${forum}/${topic}`
      : `/discussions/${topic}`;
}
